<template>
  <div>
  <h2>Suchergebnis für die Suche nach  '{{ searchstring }}' ergab folgende Suchergebnisse: </h2>
                  <b>Suche im Blog:</b><br/>
                  <ul>
                      <li v-for="(item, index) in blog" :key="'idx1'+index"><a :href="item.url" @click="jump(item.url)">{{ item.text }}</a> </li>
                  </ul>
                  <b>Suche im Rezeptbuch:</b><br/>
                  <ul>
                      <li v-for="(item2, index2) in recipes" :key="'idx2'+index2"><a :href="item2.url" @click="jump(item2.url)">{{ item2.text }}</a> </li>
                  </ul>

  </div>
</template>

<script>
export default {
  name: 'SearchResult',
/*  props : {
      searchstring : String,
  },*/
  watch: {
    '$route.params': 'update',
  },
  
  data : function() {
      return {
          searchstring : null,
          blog         : null,
          recipes      : null,
      };
  },
  methods : {
      update : function() {
          this.searchstring = this.$route.params.searchstring;

          this.$backendapi.call("search/search", this.searchstring , response => { 
              //this.blogEntries = response.data;
              this.blog = response.data.blog;
              this.recipes = response.data.recipes;
              console.log(response);
          }, (err) => {
              alert("Fehler: "+err);
          });

      },
    jump : function(target) {
      this.$router.push( {path : target } );
      this.dialog = false;
    },

  },
  mounted : function() {
      this.update();
  },
}
</script>
